import React, { useState } from "react";
import Slider from "react-slick";
import "./healthOptions.css";

const Card = ({ image, title, summary, moreInfo, onReadMore }) => (
  <div className="health-card">
    <img src={image} alt={title} className="health-card-image" />
    <h2>{title}</h2>
    <p>{summary}</p>
    <button onClick={() => onReadMore({ title, moreInfo })}>Read More</button>
  </div>
);

const Modal = ({ content, onClose }) => {
  const handleClickOutside = (e) => {
    if (e.target.className === "modal") {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>{content.title}</h2>
        {content.moreInfo}
      </div>
    </div>
  );
};

const HealthOptions = () => {
  const [modalContent, setModalContent] = useState(null);

  const cardsData = [
    {
      image: require("../../Images/healthOptions/Fixed-Benefits.png"),
      title: "Fixed Benefits",
      summary:
        "Does your health insurance plan cover everything you want it to? Is paying for your out-of-pocket healthcare costs becoming a burden? Need help with these costs? You can add a fixed benefits plan to your existing insurance policy.",
      moreInfo: (
        <>
          <p>
            Does your health insurance plan cover everything you want it to? Is
            paying for your out-of-pocket healthcare costs becoming a burden? If
            you need help with these costs, then you might want to look into
            adding a fixed benefits plan to your existing insurance policy.
            Having one of these plans could save you a lot of money, which is
            something that My MedaHealth knows a lot about. Our agents can
            answer all of your questions and will work hard to compare all the
            plans available in your area. To get you started, we’ve detailed
            below how a fixed benefit plan works - if you think one might be
            right for you, get started with an instant quote!
            <br></br>
            <br></br>A fixed benefits plan is an insurance plan that you can
            purchase as a supplement to your existing health insurance. These
            plans are not the same as regular health insurance and are not
            intended to be a substitute for comprehensive medical coverage. The
            purpose of these plans is to provide you with a fixed-dollar amount
            to help you pay for any costs associated with your healthcare.
            <br></br>
            <br></br>
            You can use the benefits without restriction, for doctor bills,
            hospital bills, telemedicine appointments, prescriptions, or any
            other medical expenses. If you experience a loss of income during
            your treatment or recovery period, you can use the money for
            household expenses, educational costs, or any other non-medical
            expenses.
            <br></br>
            <br></br>
            Fixed benefit plans are offered on a guaranteed issue basis, meaning
            that you will not be medically underwritten, and cannot be denied
            coverage due to preexisting conditions.
          </p>
          <hr></hr>
          <h2>What You Will Receive</h2>
          <p>
            Benefits paid directly to you
            <br></br>
            Discounted rates on care if you use your health insurance’s
            in-network provider
            <br></br>
            Settlement immediately following a medical event. For example, you
            will get the full insured amount upon the first diagnosis of a
            critical illness like cancer. If you are in an accident, then you or
            your beneficiaries will receive the settlement amount following your
            death or disability
            <br></br>
            <br></br>
            Benefits that can be used in any way you choose to cover
            out-of-pocket expenses
          </p>
          <hr></hr>
          <h2>What Is Covered</h2>
          <p>
            A fixed benefit plan is ideal for people that want cash benefits
            associated with:
            <br></br>
            <br></br>
            Inpatient hospital stays and covered surgeries
            <br></br>
            Outpatient surgeries, doctor visits, and tests
            <br></br>
            Prescription drugs and medical equipment
            <br></br>
            Covered injuries due to accidents (other than those that occur at
            work)
          </p>
          <hr></hr>

          <h2>Exclusions</h2>
          <p>
            Any medical or hospital services not specifically covered in your
            health plan
            <br></br>
            Cosmetic surgery, including breast reduction
            <br></br>
            Custodial care
            <br></br>
            Experimental and investigational procedures
            <br></br>
            Infertility services
            <br></br>
            Non-medically necessary services or supplies
            <br></br>
          </p>
        </>
      ),
    },
    {
      image: require("../../Images/healthOptions/Indemnity.png"),
      title: "Indemnity",
      summary:
        "An indemnity plan, also known as a “fee-for-service plan,” is one of the most flexible healthcare plans around. These plans allow you to visit any doctor, hospital or provider you choose…..",
      moreInfo: (
        <>
          <p>
            An indemnity plan, also known as a “fee-for-service plan,” is one of
            the most flexible healthcare plans around. These plans allow you to
            visit any doctor, hospital or provider you choose, which means not
            having to worry about getting hit with a giant bill for
            out-of-network care. If flexibility and choice are at the top of
            your list of insurance priorities, this might be the best option for
            you. At My MedaHealth, we can give you instant, accurate quotes on
            all of the indemnity plans available in your area and help you
            compare them to any other plan types you might be curious about.
            We’ll work hard to compare them, and will make sure you get the
            coverage you’re looking for, at a price that works with your budget.
          </p>
          <h2>An Indemnity Plan Is Perfect If:</h2>
          <p>
            You are looking for the freedom and flexibility of choosing which
            doctors and hospitals you want to go to. You do not want to choose a
            primary care physician. You do not want to get referrals to see
            specialists.
          </p>
          <h2>When Choosing A Plan</h2>
          <p>
            Some indemnity plans cover preventive care services, such as annual
            exams and routine office visits, while others do not. Some plans
            will cover a portion of these costs, and some will not allow
            preventative care to count towards your deductible. If preventative
            care is important to you, then make sure that you know how these
            services are covered before choosing a plan.
          </p>
          <h2>How It Works</h2>
          <p>
            With an indemnity plan, you don’t have to worry about staying in a
            provider network, choosing a primary care physician, or getting
            referrals to see specialists. You probably will have to pay upfront
            for services, and then submit a claim to your insurance company for
            reimbursement. You also have to meet your annual deductible before
            they will pay your claims.
          </p>
          <h2>Understanding The UCR Rate</h2>
          <p>
            With some types of healthcare plans, like PPOs and HMOs, there is a
            network of providers you can see, and your insurance company will
            negotiate with that network to lower the costs of medical services.
            Indemnity plans do not have networks, so there is no negotiating for
            lower prices. Instead, your insurance company will pay a percentage
            of your costs (after you meet your deductible). But they will not
            actually pay the bill that you get from your provider, they will pay
            a percentage of the bill based on the UCR. Most insurance companies
            set their UCR charges at the 80th percentile. That means that 80% of
            the medical providers in a given area charged equal to or less than
            the insurance company’s UCR rate. If your bill is higher than your
            insurance company’s UCR rate, you may need to pay the difference.
          </p>
          <h2>Example</h2>
          <p>
            Let’s say you have a medical procedure done and receive a bill of
            $2000, but your insurance company’s UCR for that procedure is $1500.
            If your insurance company usually pays 80% of your bill before you
            meet your deductible, they would cover 80% of $1500, and you would
            have to pay the other 20% plus the remaining $500.
          </p>
        </>
      ),
    },
    {
      image: require("../../Images/healthOptions/Small-Business.png"),
      title: "Small Business Health Insurance",
      summary:
        "As an employer, it falls on you to find the right healthcare plan for your employees. Choosing a plan is a major decision, and sorting through all of the many options can be confusing……",
      moreInfo: (
        <>
          <p>
            As an employer, it falls on you to find the right healthcare plan
            for your employees. Choosing a plan is a major decision, and sorting
            through all of the many options can be confusing. But look at it
            this way: the more options you have, the more chances you have to
            find the right plan at the right price. You can contact us directly
            to have all of your questions answered. My MedaHealth knowledgeable
            agents will go over all the group health plans available, compare
            coverage and prices, and get you instant quotes. We’ll even sign you
            up for free when you’re ready. Let’s find you a plan that will keep
            your employees healthy and happy, as well as keep more money in your
            pocket.
          </p>
          <h2>Different Options</h2>
          <p>
            Health Maintenance Organization (HMO) Plans offer affordable,
            comprehensive health coverage with low out-of-pocket costs. The one
            caveat of these plans is that employees need to stay in-network, or
            they will end up with big bills.
          </p>
          <p>
            Preferred Provider Organization (PPO) Plans have higher premiums
            than those of HMO plans because they offer larger networks and more
            flexibility.
          </p>
          <p>
            Point of Service Plans (POS) are the middle ground between HMO and
            PPO plans. Their premium prices fall somewhere in between those of
            HMOs and PPOs. They combine the lower costs of HMOs with some of the
            flexibility of PPOs.
          </p>
          <p>
            The Small Business Health Options Program (SHOP) offers Affordable
            Care Act (ACA) plans to employers who have between 1 and 50
            employees. You can choose from Bronze, Silver, Gold, and Platinum
            plans.
          </p>
          <h2>Savings</h2>
          <p>
            If you decide to go with a SHOP plan, you could be looking forward
            to a tax credit. You qualify for this credit as long as you:
          </p>
          <ul>
            <li>Have fewer than 25 full-time employees</li>
            <li>Offer health insurance to all full-time employees</li>
            <li>Pay your employees less than around $50,000 per year</li>
          </ul>
          <h2>Assess & Compare</h2>
          <p>
            When figuring out which kind of plan works best for your business’
            needs, you have to assess who you will be covering, what benefits
            are important to them, and how much cost sharing you can afford.
            After you have made these assessments, the next step is comparing
            options. There are a lot of factors to consider, such as:
          </p>
          <ul>
            <li>Monthly premiums</li>
            <li>Deductibles, copayments, and coinsurance</li>
            <li>Provider networks</li>
            <li>Prescription drug coverage</li>
            <li>Add-ons such as dental and vision</li>
          </ul>
        </>
      ),
    },
    {
      image: require("../../Images/healthOptions/Short-Term-Medical.png"),
      title: "Short Term Medical",
      summary:
        "Short-term health insurance is a temporary medical insurance plan that provides important coverage to protect you from unexpected medical bills. Is Short-Term Health Insurance Right For You?",
      moreInfo: (
        <>
          <p>
            Between jobs? Aging out of Mom or Dad’s policy? Waiting for Open
            Enrollment? A Short Term Medical plan might be just right for you.
            Short Term Medical plans let you decide how much coverage you want
            and for how long, with individual and family options available. And
            you can enjoy quality of life benefits like telemedicine and
            discounts on a variety of everyday items and services through a LIFE
            Association membership (varies by state).
          </p>
          <h2>Flexibility</h2>
          <p>Our Short Term Medical plans offer a lot of flexibility:</p>
          <ul>
            <li>
              Policies that range from 30-days to a year, some with renewal or
              consecutive options
            </li>
            <li>
              Plan options that include benefits for office visits and urgent
              care, and some with prescription benefits
            </li>
            <li>
              A wide range of deductibles, coinsurance, and benefits, so you can
              get the coverage you want
            </li>
            <li>
              Access to large national networks such as Aetna Open Choice® or
              Cigna, so you can find quality care near you.
            </li>
          </ul>
          <h2>Important Information</h2>
          <p>
            THESE PLANS PROVIDE LIMITED BENEFITS. For complete limitations &
            exclusions by state for Short Term Medical insurance, click here.
            Plan options vary by state. This coverage is not required to comply
            with federal market requirements for health insurance, principally
            those contained in the Affordable Care Act. Be sure to check your
            policy carefully to make sure you are aware of any exclusions or
            limitations regarding coverage of pre-existing conditions or health
            benefits (such as hospitalization, emergency services, maternity
            care, preventive care, prescription drugs, and mental health and
            substance use disorder services). If this coverage expires or you
            lose eligibility for this coverage, you might have to wait until an
            open enrollment period to get other health insurance coverage. This
            policy does not meet the definition of qualifying previous coverage
            or qualifying existing coverage. As a result, if purchased in lieu
            of a conversion policy or other group coverage, you may have to meet
            a pre-existing condition requirement when renewing or purchasing
            other coverage.
          </p>
        </>
      ),
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="healthWrapper">
      {cardsData.map((card, index) => (
        <div key={index} className="carousel-card">
          <Card
            image={card.image}
            title={card.title}
            summary={card.summary}
            moreInfo={card.moreInfo}
            onReadMore={setModalContent}
          />
        </div>
      ))}

      {modalContent && (
        <Modal content={modalContent} onClose={() => setModalContent(null)} />
      )}
    </div>
  );
};

export default HealthOptions;
