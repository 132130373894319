import React, { useState } from "react";

import Layout from "../../Components/Layout/layout";
import InsuranceTypes from "../../Components/InsuranceTypes/insuranceTypes";
import HealthOptions from "../../Components/ServicesComponents/healthOptions";
import ContactForm from "../../Components/ContactForm/contactForm";
import CarrierPics from "../../Images/content/carriers.png";

import "./services.css";

function Services() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  return (
    <Layout>
      <div className="servicesContainer">
        <div className="servicesTop">
          <h2>Our Services</h2>
        </div>
        <div className="servicesMiddle">
          <div className="servicePromises">
            <ul id="lBPromises">
              <li>Honest</li>

              <li>Reliable</li>

              <li>Experts</li>
            </ul>
            <ul>
              <li>Integrity</li>
              <li>Trust</li>
              <li>Security</li>
            </ul>
          </div>
          <hr className="servicesBreak"></hr>
          <p className="servicesPromise">
            Our experienced professionals are ready to answer any questions or
            concerns you have, and compare quotes at no cost to you. No pushy
            salespeople hounding you, and no obligations. Take a look at our
            products below.
          </p>
          <h1 id="productTypesH1">Product Types</h1>
          <div className="servicesSection">
            <div className="servicesTopBottom">
              <InsuranceTypes></InsuranceTypes>
              <div className="carriersCont">
                <h1>Our Carriers</h1>
                <a>
                  We only represent A-rated carriers, which means that we work
                  with the top carriers in the industry. We have established
                  strong relationships with our carriers, and they frequently
                  come to train our Associates in person. This provides our team
                  with an unparalleled level of knowledge and expertise in the
                  products and services that we offer, and allows us to pass
                  that knowledge along to our customers.
                </a>
                <img className="carrierContPic" src={CarrierPics}></img>
              </div>
            </div>

            <div className="servicesBottom">
              <h1>Health Insurance</h1>
              <HealthOptions></HealthOptions>
            </div>
          </div>
        </div>
        <div className="quoteBottom">
          <h1>INDIVIDUALS, FAMILIES AND SMALL BUSINESS HEALTH INSURANCE</h1>
          <ContactForm modalIsOpen={modalIsOpen} closeModal={closeModal} />
        </div>
      </div>
    </Layout>
  );
}

export default Services;
