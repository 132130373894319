import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./layout.css";
import medaLogo from "../../Images/whiteMeda.png";
import normalMeda from "../../Images/normalMeda.png";

const Layout = (props) => {
  const { children } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="layout">
      <header className={`menu-bar ${isScrolled ? "collapsed" : ""}`}>
        <div className="logo">
          <img className="medaLogo" src={medaLogo} alt="Meda Logo" />
        </div>
        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <Link to="/">
              <li>
                <a
                  href="#home"
                  onClick={() => {
                    setIsMenuOpen(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Home
                </a>
              </li>
            </Link>
            <Link to="/about">
              <li>
                <a
                  href="#about"
                  onClick={() => {
                    setIsMenuOpen(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  About
                </a>
              </li>
            </Link>
            <Link to="/services">
              <li>
                <a
                  href="#services"
                  onClick={() => {
                    setIsMenuOpen(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Services
                </a>
              </li>
            </Link>
            <Link to="/carriers">
              <li>
                <a
                  href="#carriers"
                  onClick={() => {
                    setIsMenuOpen(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Carriers
                </a>
              </li>
            </Link>
            <Link to="/contact">
              <li>
                <a
                  href="#contact"
                  onClick={() => {
                    setIsMenuOpen(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Contact
                </a>
              </li>
            </Link>
          </ul>
        </nav>
        <div
          className={`hamburger ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <main>{children}</main>
      <footer>
        <img className="medaLogo2" src={normalMeda} alt="Meda Logo" />
        <ul>
          <Link to="/">
            <li>
              <a
                href="#home"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Home
              </a>
            </li>
          </Link>
          <Link
            to="/about"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <li>
              <a href="#about">About</a>
            </li>
          </Link>
          <Link
            to="/services"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <li>
              <a
                href="#services"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Services
              </a>
            </li>
          </Link>
          <Link
            to="/contact"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <li>
              <a href="#contact">Contact</a>
            </li>
          </Link>
        </ul>
        <div className="contactInfo">
          <p>501 E. KENNEDY BLVD.</p>
          <p>SUITE 100</p>
          <p>TAMPA, FL 33602</p>
          <p>info@mymedahealth.com</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
