import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonialSlider.css"; // Import custom CSS for additional styling

const TestimonialSection = () => {
  const testimonials = [
    {
      name: "John Doe",
      state: "California",
      products: ["Auto Insurance", "Home Insurance"],
      testimonial:
        "Great service! The team was very helpful and found me the best rates for my needs.",
    },
    {
      name: "Jane Smith",
      state: "Texas",
      products: ["Health Insurance", "Life Insurance"],
      testimonial:
        "I had an amazing experience. Very professional and attentive.",
    },
    {
      name: "Michael Johnson",
      state: "New York",
      products: ["Business Insurance"],
      testimonial:
        "They understood my business requirements and provided tailored solutions.",
    },
    // Add more testimonials as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
  };

  return (
    <div className="testimonial-section">
      <h2>What Our Clients Say</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <p className="testimonial-state">{testimonial.state}</p>
            <p className="testimonial-products">
              Products: {testimonial.products.join(", ")}
            </p>
            <p className="testimonial-text">"{testimonial.testimonial}"</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSection;
