import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import OurValues from "../../Components/Values/values";
import ContactForm from "../../Components/ContactForm/contactForm";
import whatWeDo from "../../Images/content/whatWeDo.png";
import medaLogo from "../../Images/normalMeda.png";
import "./home.css";
import Layout from "../../Components/Layout/layout";

function Home() {
  const navigate = useNavigate();
  const testimonialsSection = useRef(null);
  const whatWeDoPicRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);

  const scrollToTestimonials = () => {
    navigate("/about", { state: { scrollToTestimonials: true } });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    const slideInElements = document.querySelectorAll(".slideIn");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log("Entry:", entry.isIntersecting); // Log visibility
          if (entry.isIntersecting) {
            entry.target.classList.add("slideInLeft");
          } else {
            entry.target.classList.remove("slideInLeft");
          }
        });
      },
      { threshold: 0.1 }
    );

    slideInElements.forEach((el) => observer.observe(el));

    return () => {
      slideInElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <Layout>
      <div className="homeWrapper">
        <div className="homeSplashSection">
          <div className="homeSplashContainer">
            <div className="homeSplashContent">
              <div className="topTop">
                <h1>Let us help you protect yourself and those you love</h1>
              </div>
              <div className="conButtContainer">
                <ContactForm
                  className="splashContactButton"
                  modalIsOpen={modalIsOpen}
                  closeModal={closeModal}
                />
              </div>
              <div className="homeSplashContentBottom">
                <div className="lowCont">
                  <h2>
                    Affordable peace of mind is just a call or click away!
                  </h2>
                  <h3
                    className="testimonialLink"
                    onClick={scrollToTestimonials}
                  >
                    See what clients are saying..
                  </h3>
                  <img src={medaLogo} className="splashLogo" alt="Meda Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutWrapper">
          <h2 id="whiteH1">What We Do</h2>
          <div className="aboutContainer">
            <div className="aboutContent slideIn">
              <p>
                Looking for insurance can be stressful, because there are so
                many different plans with varying prices and coverage. It
                requires a lot of research to find the perfect plan, but having
                insurance is important, and having an agent by your side is even
                more important.
              </p>
              <p>
                It gives you peace of mind of knowing you’re covered in case of
                the unexpected. If doing all the research begins to feel
                overwhelming,
              </p>
            </div>
            <div className="aboutPicContainer">
              <img
                className={`whatWeDoPic slideIn`}
                src={whatWeDo}
                alt="What We Do"
                ref={whatWeDoPicRef}
              />
            </div>
          </div>
          <div className="valuesWrapper slideIn">
            <h1>Our Values</h1>
            <OurValues />
          </div>
        </div>

        <div className="quoteBottom">
          <h1>INDIVIDUALS, FAMILIES AND SMALL BUSINESS HEALTH INSURANCE</h1>
          <ContactForm modalIsOpen={modalIsOpen} closeModal={closeModal} />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
