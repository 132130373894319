import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../Components/Layout/layout";
import "./about.css";
import Testimonials from "../../Components/HomeComponents/testimonialSlider";
import ContactForm from "../../Components/ContactForm/contactForm";

const About = () => {
  const [modalIsOpenContact, setModalIsOpenContact] = useState(false);
  const testimonialSectionRef = useRef(null);
  const location = useLocation();

  const openModalContact = () => setModalIsOpenContact(true);
  const closeModalContact = () => setModalIsOpenContact(false);

  useLayoutEffect(() => {
    if (location.state?.scrollToTestimonials && testimonialSectionRef.current) {
      testimonialSectionRef.current.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        const offset = window.innerHeight * 0.1;
        window.scrollBy(0, -offset);
      }, 1000);
    }
  }, [location.key]);

  useEffect(() => {
    const slideInElements = document.querySelectorAll(".slideIn");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log("Entry:", entry.isIntersecting); // Log visibility
          if (entry.isIntersecting) {
            entry.target.classList.add("slideInLeft");
          } else {
            entry.target.classList.remove("slideInLeft");
          }
        });
      },
      { threshold: 0.1 }
    );

    slideInElements.forEach((el) => observer.observe(el));

    return () => {
      slideInElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <Layout>
      <div className="aboutContainer">
        <div className="aboutSplash">
          <h1>About MedaHealth</h1>
        </div>
        <div className="aboutSection">
          <div className="aboutSecTop">
            <h3>Protect What Matters Most with Meda Health</h3>
            <p>
              At MedaHealth Associates, we understand that peace of mind is
              invaluable. As a trusted insurance brokerage, we’re dedicated to
              offering a complete range of coverage options to safeguard your
              health, family, and future.
            </p>
            <h3>Comprehensive Coverage, Tailored for You</h3>
            <p>
              From life insurance that secures your family’s future to health,
              vision, and dental plans that keep you and your loved ones
              covered, Meda Health Associates provides personalized solutions
              for every stage of life. Our team of knowledgeable advisors works
              closely with you to find the best-fit policies that protect what
              matters most.
            </p>
          </div>

          <ul className="aboutList">
            <h3 id="aboutListTitle">Why Choose MedaHealth Associates?</h3>
            <hr className="aboutListBrake"></hr>
            <li id="aboutListItems" className="slideIn">
              <b>Personalized Service:</b> We believe in understanding your
              unique needs and guiding you toward the right coverage.
            </li>
            <li id="aboutListItems" className="slideIn">
              <b>Trusted Partnerships:</b> We work with reputable insurance
              providers to bring you high-quality options.
            </li>
            <li id="aboutListItems" className="slideIn">
              <b>Full-Spectrum Protection:</b> Offering life, health, vision,
              dental, accidental, and supplemental coverage ensures that all
              aspects of your life are secured.
            </li>
          </ul>
          <h3>Let’s Secure Your Peace of Mind</h3>
          <p>
            Whether you’re looking to safeguard your health, secure your
            family’s future, or prepare for the unexpected, MedaHealth
            Associates is here for you. We’re committed to providing clarity and
            confidence in every choice you make.
          </p>
        </div>
        <div ref={testimonialSectionRef} className="testimonialSection">
          <Testimonials />
        </div>
        <div className="quoteBottom">
          <h1>INDIVIDUALS, FAMILIES AND SMALL BUSINESS HEALTH INSURANCE</h1>
          <ContactForm
            modalIsOpen={modalIsOpenContact}
            closeModal={closeModalContact}
          />
        </div>
      </div>
    </Layout>
  );
};

export default About;
